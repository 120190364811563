export const useSiteStore = defineStore(
    "site",
    () => {
        const state = reactive({
            holidayFilterState: null,
            holidayFilterStateBarOnly: null
        });

        const setHolidayFilterState = (value) => {
            state.holidayFilterState = value;
            state.holidayFilterStateBarOnly = value;
        };

        return {
            state,
            setHolidayFilterState
        }
    },
    {
        persist: {
            storage: persistedState.localStorage,
            paths: ['holidayFilterState']
        },
    }
);